import { lighten } from "polished";

// import {
// 	primaryColor,
// 	warningColor,
// 	dangerColor,
// 	successColor,
// 	infoColor,
// 	roseColor,
// 	grayColor,
// 	blackColor,
// 	whiteColor,
// } from "./jss/material-dashboard-react.js";
export const primaryColor = ["#9124A3", "#ab47bc", "#8e24aa", "#af2cc5"];
export const warningColor = ["#FFB22B", "#FFB22B", "#FFB22B", "#FFB22B"];
export const dangerColor = ["#FC4B6C", "#FC4B6C", "#FC4B6C", "#FC4B6C"];
export const successColor = ["#21C1D6", "#21C1D6", "#21C1D6", "#21C1D6"];
export const infoColor = ["#1E88E5", "#1E88E5", "#1E88E5", "#1E88E5"];
export const roseColor = ["#745AF2", "#745AF2", "#745AF2", "#745AF2"];
export const grayColor = [
  "#999",
  "#777",
  "#3C4858",
  "#AAAAAA",
  "#D2D2D2",
  "#DDD",
  "#b4b4b4",
  "#555555",
  "#333",
  "#a9afbb",
  "#eee",
  "#e7e7e7"
];
export const blackColor = "#000";
export const whiteColor = "#FFF";


export const BgPrimaryColor = lighten(0.1, primaryColor[0]);
export const BgLightPrimaryColor = lighten(0.25, BgPrimaryColor);
export const BgWarningColor = lighten(0.1, warningColor[0]);
export const BgDangerColor = lighten(0.1, dangerColor[0]);
export const BgSuccessColor = lighten(0.1, successColor[0]);
export const BgLightSuccessColor = lighten(0.35, successColor[0]);
export const BgInfoColor = lighten(0.1, infoColor[0]);
export const BgLightInfoColor = lighten(0.1, infoColor[0]);
export const BgRoseColor = lighten(0.1, roseColor[0]);
export const BgLightRoseColor = lighten(0.25, roseColor[0]);
export const BgGrayColor = lighten(0.1, grayColor[0]);
export const BgLightGrayColor = lighten(0.35, grayColor[0]);
export const BgBlackColor = lighten(0.1, blackColor);
export const BgWhiteColor = lighten(0.1, whiteColor);

export const BgColors = [
	BgPrimaryColor,
	BgWarningColor,
	BgDangerColor,
	BgSuccessColor,
	BgInfoColor,
	BgRoseColor,
	BgGrayColor,
	BgBlackColor,
	BgWhiteColor,
];
