import React, { useEffect } from "react";
import { getAllCompany, refresh } from "../apiClient";
import { useHistory, useLocation } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

const useStyles = makeStyles((theme) => ({
	paper: {
		position: "absolute",
		width: 66,
		// backgroundColor: theme.palette.background.paper,
		border: "none",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		"&:focus": {
			outline: "none",
		},
    },
    backdrop: {
        backgroundColor: "#FFF",
        opacity: 1,

    }
}));

export default function SimpleModal() {
	const classes = useStyles();
	// getModalStyle is not a pure function, we roll the style only on the first render
	const [modalStyle] = React.useState(getModalStyle);
	const [open, setOpen] = React.useState(true);
	// const refreshToken = localStorage.getItem("refresh_token");
	const history = useHistory();
	const location = useLocation();
	const stateAccessToken = location.state?.accessToken;
	if (stateAccessToken) {
		setOpen(false);
	}
	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const body = (
		<div style={modalStyle} className={classes.paper}>
			<CircularProgress />
		</div>
	);

	useEffect(() => {
		const token = localStorage.getItem("refresh_token");
		getAllCompany()
			.then((response) => {
				if (response.status !== 200) {
					refresh()
						.then((response) => {
							localStorage.removeItem("accessToken");
							localStorage.removeItem("refresh_token");
							localStorage.setItem("accessToken", response.data.access_token);
							// localStorage.setItem("role", response.data.access);
							localStorage.setItem("refresh_token", token);
                            setOpen(false);
							history.push({
								pathname: location.pathname,
								// state: { signIn: response.data },
							});
						})
						.catch(() => {
						
							history.push("/", { referer: location.pathname });
						});
				} else {
					setOpen(false);
				}
			})
			.catch((e) => {
				refresh()
					.then((response) => {
						localStorage.removeItem("accessToken");
						localStorage.removeItem("refresh_token");
						localStorage.setItem("accessToken", response.data.access_token);
						// localStorage.setItem("role", response.data.access);
                        localStorage.setItem("refresh_token", token);
                        setOpen(false);
						history.push({
							pathname: location.pathname,
							state: { signIn: response.data },
						});
					})
					.catch(() => {
						history.push("/", { referer: location.pathname });
					});
			});
	}, []);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
            // BackdropProps={{classes: {root: classes.backdrop}}}
		>
			{body}
		</Modal>
	);
}
