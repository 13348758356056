import {
	BookOpen,
	Briefcase,
	Calendar as CalendarIcon,
	CheckSquare,
	CreditCard,
	Grid,
	Heart,
	Layout,
	List,
	Map,
	Monitor,
	PieChart,
	ShoppingCart,
	Sliders,
	User,
	Users as UsersIcon,
} from "react-feather";
import { isAdmin, isSuperAdmin,getCompanyName } from "../utils";

import React from "react";
import async from "../components/Async";

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const ForgetPassword = async(() => import("../pages/auth/ForgetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Components components
const Alerts = async(() => import("../pages/components/Alerts"));
const Avatars = async(() => import("../pages/components/Avatars"));
const Badges = async(() => import("../pages/components/Badges"));
const Buttons = async(() => import("../pages/components/Buttons"));
const Cards = async(() => import("../pages/components/Cards"));
const Chips = async(() => import("../pages/components/Chips"));
const Dialogs = async(() => import("../pages/components/Dialogs"));
const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
const Lists = async(() => import("../pages/components/Lists"));
const Menus = async(() => import("../pages/components/Menus"));
const Pagination = async(() => import("../pages/components/Pagination"));
const Progress = async(() => import("../pages/components/Progress"));
const Snackbars = async(() => import("../pages/components/Snackbars"));
const Tooltips = async(() => import("../pages/components/Tooltips"));

// Dashboards components
const Default = async(() => import("../pages/dashboards/Default"));
const Analytics = async(() => import("../pages/dashboards/Analytics"));
const Datasets = async(() => import("../pages/dashboards/Datasets"));
const Assessments = async(() => import("../pages/dashboards/Assessments"));
const AssessmentDetails = async(() => import("../pages/dashboards/AssessmentDetails"));
const AssessmentDetails360 = async(() => import("../pages/dashboards/AssessmentDetails360"));
const PreAssessmentDetails = async(() => import("../pages/dashboards/PreAssessmentDetails"));
const QuotesDetails = async(() => import("../pages/dashboards/QuotesDetails"));
const Quotes = async(() => import("../pages/dashboards/Quotes"));
const QuotesAssignedForAssessment = async(() => import("../pages/dashboards/QuotesAssignedForAssessment"));
const QuotesData = async(() => import("../pages/dashboards/QuotesData"));
const AiDetails = async(() => import("../pages/dashboards/AiDetails"));
const AiDetails_Pre = async(() => import("../pages/dashboards/AiDetails_Pre"));
const AiReport = async(() => import("../pages/dashboards/AiReport"));
const Users = async(() => import("../pages/dashboards/Users"));
const AddUser = async(() => import("../pages/dashboards/Users/AddUser"));
const Organizations = async(() => import("../pages/dashboards/Organizations"));
const AddOrganizations = async(() => import("../pages/dashboards/Users/AddOrganisation"));
const PreInspection = async(() => import("../pages/dashboards/PreInspection"));
const QuotesToRepair = async(()=> import("../pages/dashboards/QuoteToRepair"));
const CarListing = async(() => import("../pages/dashboards/Carlisting"));

// Forms components
const Pickers = async(() => import("../pages/forms/Pickers"));
const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
const Selects = async(() => import("../pages/forms/Selects"));
const TextFields = async(() => import("../pages/forms/TextFields"));
const Dropzone = async(() => import("../pages/forms/Dropzone"));
const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
const Orders = async(() => import("../pages/pages/Orders"));
const Pricing = async(() => import("../pages/pages/Pricing"));
const Profile = async(() => import("../pages/pages/Profile"));
const Settings = async(() => import("../pages/pages/Settings"));
const Tasks = async(() => import("../pages/pages/Tasks"));
const Projects = async(() => import("../pages/pages/Projects"));
const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components

// Chart components
const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

// Documentation
const Welcome = async(() => import("../pages/docs/Welcome"));
const GettingStarted = async(() => import("../pages/docs/GettingStarted"));
const EnvironmentVariables = async(() =>
	import("../pages/docs/EnvironmentVariables"),
);
const Deployment = async(() => import("../pages/docs/Deployment"));
const Theming = async(() => import("../pages/docs/Theming"));
const StateManagement = async(() => import("../pages/docs/StateManagement"));
const Support = async(() => import("../pages/docs/Support"));
const Changelog = async(() => import("../pages/docs/Changelog"));
const Presentation = async(() => import("../pages/docs/Presentation"));

const superAdminRoutes = {
	id: "Admin",
	path: "/dashboard",
	header: "Admin",
	icon: <Sliders />,
	containsHome: true,
	children: [
		{
			path: "/dashboard",
			name: "Dashboard",
			component: Default,
		},
		// {
		// 	path: "/dashboard/analytics",
		// 	name: "Analytics",
		// 	component: Analytics,
		// },
		{
			path: "/dashboard/dataset",
			name: "DataSets",
			component: Datasets,
		},
		{
			path: "/dashboard/organizations",
			name: "Organizations",
			component: Organizations,
		},
	],
	component: null,
};

const adminRoutes = {
	id: "Admin",
	path: "/dashboard",
	header: "Admin",
	icon: <Sliders />,
	containsHome: true,
	children: [
		{
			path: "/dashboard",
			name: "Dashboard",
			component: Default,
		},
		{
			path: "/dashboard/users",
			name: "Users",
			component: Users,
		},
		{
			path: "/dashboard/companies",
			name: "Companies",
			component: Organizations,
		},
		{
			path: "/dashboard/companies/addNew",
			// name: "Add User",
			component: AddOrganizations,
		},
		{
			path: "/dashboard/companies/edit",
			// name: "Add User",
			component: AddOrganizations,
		},
		{
			path: "/dashboard/users/addNew",
			// name: "Add User",
			component: AddUser,
		},
		{
			path: "/dashboard/users/edit",
			// name: "Add User",
			component: AddUser,
		},
		{
			path: "/dashboard/assessments",
			name: "Assessments",
			isAccordian: true,
			icon: <Sliders />,
			accordianChildren: [
				{
					path: "/dashboard/pre-inspection",
					name: "Pre Inspection",
					visible: true,
					// component: PreInspection,
				},
				{
					path: "/dashboard/claims-submission",
					name: "Claims Submission",
					visible: true,
				},
				{
					path: "/dashboard/car-listing",
					name: "Car Listing",
					visible: getCompanyName() === "carscan" ||  getCompanyName() === "mccarthy" ||  getCompanyName() === "carscandemo",
				},
				{
					path: "/dashboard/quotes-to-repair",
					name: "Quotes To Repair",
					visible:  getCompanyName() === 'prtuma' ||  getCompanyName() === 'carscan'||  getCompanyName() === "carscandemo"
				}
			]
		},
		{
			path: "/dashboard/pre-inspection",
			// name: "Pre Inspection",
			component: PreInspection,
		},
		{
			path: "/dashboard/claims-submission",
			// name: "Pre Inspection",
			component: Assessments,
		},
		{
			path: "/dashboard/quotes-to-repair",
			// name: "Pre Inspection",
			component: QuotesToRepair,
		},
		{
			path: "/dashboard/quotes-to-repair/quotes",
			// name: "Assessments",
			component: Quotes,
		},
		{
			path: "/dashboard/quotes-to-repair/quotesAssignedForAssessment",
			// name: "Assessments",
			component: QuotesAssignedForAssessment,
		},
		{
			path: "/dashboard/car-listing",
			// name: "Car Listing",
			component: CarListing,
		},
		{
			path: "/dashboard/pre-inspection/quotes",
			// name: "Assessments",
			component: Quotes,
		},
		{
			path: "/dashboard/pre-inspection/details",
			// name: "Assessments",
			component: PreAssessmentDetails,
		},
		{
			path: "/dashboard/quotes-to-repair/details",
			// name: "Assessments",
			component: QuotesDetails,
		},
		{
			path: "/dashboard/pre-inspection/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/quotes-to-repair/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/pre-inspection/aiReports",
			// name: "Assessments",
			component: AiDetails_Pre,
		},
		{
			path: "/dashboard/pre-inspection/aiReports/details",
			// name: "Assessments",
			component: AiReport,
		},
		{
			path: "/dashboard/quotes-to-repair/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/quotes-to-repair/aiReports",
			// name: "Assessments",
			component: AiDetails_Pre,
		},
		{
			path: "/dashboard/quotes-to-repair/aiReports/details",
			// name: "Assessments",
			component: AiReport,
		},
		{
			path: "/dashboard/claims-submission/aiReports/details",
			// name: "Assessments",
			component: AiReport,
		},

		{
			path: "/dashboard/pre-inspection/quotesAssignedForAssessment",
			// name: "Assessments",
			component: QuotesAssignedForAssessment,
		},

		{
			path: "/dashboard/claims-submission/quotesAssignedForAssessment",
			// name: "Assessments",
			component: QuotesAssignedForAssessment,
		},


		{
			path: "/dashboard/claims-submission/quotes",
			// name: "Assessments",
			component: Quotes,
		},
		{
			path: "/dashboard/claims-submission/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/claims-submission/details",
			// name: "Assessments",
			component: AssessmentDetails,
			// component: AssessmentDetails360
		},
		{
			path: "/dashboard/car-listing/details",
			// name: "Assessments",
			component: getCompanyName() === "carscan"||getCompanyName() === "mccarthy"||getCompanyName() === "Carscan45" ? AssessmentDetails360 : AssessmentDetails,
		},
		{
			path: "/dashboard/car-listing/quotesAssignedForAssessment",
			// name: "Assessments",
			component: QuotesAssignedForAssessment,
		},
		{
			path: "/dashboard/car-listing/aiReports",
			// name: "Assessments",
			component: AiDetails_Pre,
		},
		{
			path: "/dashboard/car-listing/aiReports/details",
			// name: "Assessments",
			component: AiReport,
		},
		{
			path: "/dashboard/claims-submission/aiReports",
			// name: "Assessments",
			component: AiDetails,
		},
		{
			path: "/dashboard/claims-submission/aiReports/details",
			// name: "Assessments",
			component: AiReport,
		},
		{
			path: "/dashboard/car-listing/quotes",
			// name: "Assessments",
			component: Quotes,
		},
		{
			path: "/dashboard/car-listing/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/claims-submission/quotesAssignedForAssessment",
			// name: "Assessments",
			component: QuotesAssignedForAssessment,
		},
		
		
		// {
		// 	path: "/dashboard/cases",
		// 	name: "Assign Cases",
		// 	component: Cases,
		// },
	
	],
	component: null,
};

const userRoutes = {
	id: "User",
	path: "/user",
	header: "User",
	icon: <Sliders />,
	containsHome: true,
	children: [
		{
			path: "/dashboard",
			name: "Dashboard",
			component: Default,
		},
		{
			path: "/dashboard/assessments",
			name: "Assessments",
			component: Assessments,
			isAccordian: true,
			icon: <Sliders />,
			accordianChildren: [
				{
					path: "/dashboard/pre-inspection",
					name: "Pre Inspection",
					visible: true,
					// component: PreInspection,
				},
				{
					path: "/dashboard/claim-inspection",
					name: "Claim Submission",
					visible: getCompanyName() !== "motus" && getCompanyName() !== "mccarthy",
				},
				{
					path: "/dashboard/quotes-to-repair",
					name: "Quotes To Repair",
					visible: getCompanyName() === 'prtuma' || getCompanyName()=== "carscan" ||  getCompanyName() === "carscandemo"|| getCompanyName() === "mccarthy" 
				},
				{
					path: "/dashboard/car-listing",
					name: "Car Listing",
					visible: getCompanyName() === "carscan" || getCompanyName() === "carscan45" || getCompanyName() === "motus" ||  getCompanyName() === "carscandemo",
				},
				// {
				// 	path: "/dashboard/car-listing",
				// 	name: "Car Listing",
				// 	disable: true
					// component: CarListing,
				// },
			]
		},
		{
			path: "/dashboard/pre-inspection",
			// name: "Pre Inspection",
			component: PreInspection,
		},
		{
			path: "/dashboard/claim-inspection",
			// name: "Pre Inspection",
			component: Assessments,
		},
		{
			path: "/dashboard/car-listing",
			// name: "Car Listing",
			component: CarListing,
		},
		{
			path: "/dashboard/quotes-to-repair",
			// name: "Pre Inspection",
			component: QuotesToRepair,
		},
		{
			path: "/dashboard/assessments/quotes",
			// name: "Assessments",
			component: Quotes,
		},
		{
			path: "/dashboard/assessments/quotes/data",
			// name: "Assessments",
			component: QuotesData,
		},
		{
			path: "/dashboard/assessments/details",
			// name: "Assessments",
			component: AssessmentDetails,
		},
		{
			path: "/dashboard/assessments/aiDetails",
			// name: "Assessments",
			component: AiDetails,
		},
		{
			path: "/dashboard/car-listing/details",
			// name: "Assessments",
			component: getCompanyName() === "carscan" ? AssessmentDetails360 : AssessmentDetails,
		},
	],
	component: null,
};

const dashboardsRoutes = {
	id: "Dashboard",
	path: "/dashboard",
	header: "Pages",
	icon: <Sliders />,
	containsHome: true,
	children: [
		{
			path: "/dashboard",
			name: "Default",
			component: Default,
		},
		{
			path: "/dashboard/analytics",
			name: "Analytics",
			component: Analytics,
		},
	],
	component: null,
};

const pagesRoutes = {
	id: "Pages",
	path: "/pages",
	icon: <Layout />,
	children: [
		{
			path: "/pages/settings",
			name: "Settings",
			component: Settings,
		},
		{
			path: "/pages/pricing",
			name: "Pricing",
			component: Pricing,
		},
		{
			path: "/pages/blank",
			name: "Blank Page",
			component: Blank,
		},
	],
	component: null,
};

const profileRoutes = {
	id: "Profile",
	path: "/profile",
	icon: <User />,
	component: Profile,
	children: null,
};

const projectsRoutes = {
	id: "Projects",
	path: "/projects",
	icon: <Briefcase />,
	badge: "8",
	component: Projects,
	children: null,
};

const invoiceRoutes = {
	id: "Invoices",
	path: "/invoices",
	icon: <CreditCard />,
	children: [
		{
			path: "/invoices",
			name: "List",
			component: InvoiceList,
		},
		{
			path: "/invoices/detail",
			name: "Details",
			component: InvoiceDetails,
		},
	],
	component: null,
};

const orderRoutes = {
	id: "Orders",
	path: "/orders",
	icon: <ShoppingCart />,
	component: Orders,
	children: null,
};

const tasksRoutes = {
	id: "Tasks",
	path: "/tasks",
	icon: <CheckSquare />,
	badge: "17",
	component: Tasks,
	children: null,
};

const calendarRoutes = {
	id: "Calendar",
	path: "/calendar",
	icon: <CalendarIcon />,
	component: Calendar,
	children: null,
};

const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <UsersIcon />,
	children: [
		{
			path: "/",
			name: "Sign In",
			component: SignIn,
		},
		{
			path: "/auth/reset-password/:resetToken",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/auth/forget-password",
			name: "Reset Password",
			component: ForgetPassword,
		},
		{
			path: "/auth/404",
			name: "404 Page",
			component: Page404,
		},
		{
			path: "/auth/500",
			name: "500 Page",
			component: Page500,
		},
	],
	component: null,
};

const componentsRoutes = {
	id: "Components",
	path: "/components",
	header: "Elements",
	icon: <Grid />,
	children: [
		{
			path: "/components/alerts",
			name: "Alerts",
			component: Alerts,
		},
		{
			path: "/components/avatars",
			name: "Avatars",
			component: Avatars,
		},
		{
			path: "/components/badges",
			name: "Badges",
			component: Badges,
		},
		{
			path: "/components/buttons",
			name: "Buttons",
			component: Buttons,
		},
		{
			path: "/components/cards",
			name: "Cards",
			component: Cards,
		},
		{
			path: "/components/chips",
			name: "Chips",
			component: Chips,
		},
		{
			path: "/components/dialogs",
			name: "Dialogs",
			component: Dialogs,
		},
		{
			path: "/components/expansion-panels",
			name: "Expansion Panels",
			component: ExpPanels,
		},
		{
			path: "/components/lists",
			name: "Lists",
			component: Lists,
		},
		{
			path: "/components/menus",
			name: "Menus",
			component: Menus,
		},
		{
			path: "/components/pagination",
			name: "Pagination",
			component: Pagination,
		},
		{
			path: "/components/progress",
			name: "Progress",
			component: Progress,
		},
		{
			path: "/components/snackbars",
			name: "Snackbars",
			component: Snackbars,
		},
		{
			path: "/components/tooltips",
			name: "Tooltips",
			component: Tooltips,
		},
	],
	component: null,
};

const formsRoutes = {
	id: "Forms",
	path: "/forms",
	icon: <CheckSquare />,
	children: [
		{
			path: "/forms/pickers",
			name: "Pickers",
			component: Pickers,
		},
		{
			path: "/forms/selection-controls",
			name: "Selection Controls",
			component: SelectionCtrls,
		},
		{
			path: "/forms/selects",
			name: "Selects",
			component: Selects,
		},
		{
			path: "/forms/text-fields",
			name: "Text Fields",
			component: TextFields,
		},
		{
			path: "/forms/dropzone",
			name: "Dropzone",
			component: Dropzone,
		},
		{
			path: "/forms/editors",
			name: "Editors",
			component: Editors,
		},
	],
	component: null,
};

const tablesRoutes = {
	id: "Tables",
	path: "/tables",
	icon: <List />,
	component: null,
};

const iconsRoutes = {
	id: "Icons",
	path: "/icons",
	icon: <Heart />,
	children: [
		{
			path: "/icons/material-icons",
			name: "Material Icons",
			component: MaterialIcons,
		},
		{
			path: "/icons/feather-icons",
			name: "Feather Icons",
			component: FeatherIcons,
		},
	],
	component: null,
};

const chartRoutes = {
	id: "Charts",
	path: "/charts",
	icon: <PieChart />,
	component: Chartjs,
	children: null,
};

const mapsRoutes = {
	id: "Maps",
	path: "/maps",
	icon: <Map />,
	children: [
		{
			path: "/maps/google-maps",
			name: "Google Maps",
			component: GoogleMaps,
		},
		{
			path: "/maps/vector-maps",
			name: "Vector Maps",
			component: VectorMaps,
		},
	],
	component: null,
};

const presentationRoutes = {
	id: "Presentation",
	path: "/presentation",
	header: "Docs",
	icon: <Monitor />,
	component: Presentation,
	children: null,
};

const documentationRoutes = {
	id: "Documentation",
	path: "/documentation",
	icon: <BookOpen />,
	children: [
		{
			path: "/documentation/welcome",
			name: "Welcome",
			component: Welcome,
		},
		{
			path: "/documentation/getting-started",
			name: "Getting Started",
			component: GettingStarted,
		},
		{
			path: "/documentation/environment-variables",
			name: "Environment Variables",
			component: EnvironmentVariables,
		},
		{
			path: "/documentation/deployment",
			name: "Deployment",
			component: Deployment,
		},
		{
			path: "/documentation/theming",
			name: "Theming",
			component: Theming,
		},
		{
			path: "/documentation/state-management",
			name: "State Management",
			component: StateManagement,
		},
		{
			path: "/documentation/support",
			name: "Support",
			component: Support,
		},
	],
	component: null,
};

const changelogRoutes = {
	id: "Changelog",
	path: "/changelog",
	badge: "v1.2.0",
	icon: <List />,
	component: Changelog,
	children: null,
};

// This route is not visisble in the sidebar
const privateRoutes = {
	id: "Private",
	path: "/private",
	component: Blank,
	children: null,
};

// Routes using the Dashboard layout
export let dashboardLayoutRoutes = [];
dashboardLayoutRoutes = [
	superAdminRoutes,
	adminRoutes,
	userRoutes,
	dashboardsRoutes,
	pagesRoutes,
	profileRoutes,
	projectsRoutes,
	orderRoutes,
	invoiceRoutes,
	tasksRoutes,
	calendarRoutes,
	componentsRoutes,
	chartRoutes,
	formsRoutes,
	tablesRoutes,
	iconsRoutes,
	mapsRoutes,
	documentationRoutes,
	changelogRoutes,
	presentationRoutes,
	privateRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes visible in the sidebar
export let sidebarRoutes = [];
if (isSuperAdmin()) {
	sidebarRoutes.push(superAdminRoutes);
}
if (isAdmin()) {
	sidebarRoutes.push(adminRoutes);
}

if(!isAdmin() && !isSuperAdmin()) {
  sidebarRoutes.push(userRoutes);
}

export const getRoutes  = (role = null) => {
  const sidebarRoutes = []
 
  if (isSuperAdmin(role)) {
    sidebarRoutes.push(superAdminRoutes);
  }
  
  if (isAdmin(role)) {
	sidebarRoutes.push(adminRoutes);
  }
  
  if(!isAdmin(role) && !isSuperAdmin(role)) {
    sidebarRoutes.push(userRoutes);
  }
  return sidebarRoutes;
}
// sidebarRoutes = [
// 	...sidebarRoutes,
// 	...[
// 		dashboardsRoutes,
// 		pagesRoutes,
// 		profileRoutes,
// 		projectsRoutes,
// 		orderRoutes,
// 		invoiceRoutes,
// 		tasksRoutes,
// 		calendarRoutes,
// 		authRoutes,
// 		componentsRoutes,
// 		chartRoutes,
// 		formsRoutes,
// 		tablesRoutes,
// 		iconsRoutes,
// 		mapsRoutes,
// 		presentationRoutes,
// 		documentationRoutes,
// 		changelogRoutes,
// 	],
// ];
