import { DateTime } from "luxon";

export const isAdmin = (role) => {
	const userRole = role === null ? localStorage.getItem("role") : role;
	return userRole === "admin";
};

export const isSuperAdmin = (role) => {
	const userRole = role === null ? localStorage.getItem("role") : role;
	return userRole === "super-admin";
};

export const getRole = (role) => {
	const userRole = role ? role : localStorage.getItem("role");
	return userRole;
};

export const getUserName = (userName) => {
	const user = userName ? userName : localStorage.getItem("username");
	return user;
};

export const getCompanyName = (companyName) => {
	const company = companyName ? companyName : localStorage.getItem("company_name");
	return company;
};

export const getLogoURL = (logoURL) => {
	const logo_URL = logoURL  ? logoURL: localStorage.getItem("logo_url") ;
	return logo_URL;
};

export const isEmpty = (obj) => {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
};

export const getDates = (duration = 30) => {
	const endDate = DateTime.local();
	const startDate = DateTime.local().minus({ days: duration });
	const categories = [];
	let cDate = startDate;
	for (let i = 0; i < duration; i++) {
		categories.push(cDate.toFormat("MM-dd"));
		cDate = cDate.plus({ days: 1 });
	}
	return { startDate, endDate, categories };
};

export const getDatesAsPerDtRng = (duration = 30, startDt) => {
	const endDate = DateTime.local();
	const startDate = DateTime.fromISO(startDt);
	const categories = [];
	let cDate = startDate;
	for (let i = 0; i < duration; i++) {
		categories.push(cDate.toFormat("MM-dd"));
		cDate = cDate.plus({ days: 1 });
	}
	return { startDate, endDate, categories };
};

export const getDateFromSeconds = (epoch) => {
	return DateTime.fromMillis(epoch);
};

export const getLabel = (unformattedText)=>{
	let formattedText = "";
	switch (unformattedText){
		case "comments_text":
			formattedText = "Comments Text";
			break;
		case "PreferredRepairLocation":
			formattedText = "Preferred Repair Location";
			break;
		case "claimingArea": 
			formattedText = "Claiming Area";
			break;
		case "policyno":
			formattedText = "Policy No";
			break;
		case "name":
			formattedText = "Name";
			break;
		case "surname":
			formattedText = "Surname";
			break;
		case "contactnumber":
			formattedText = "Contact Number";
			break;
		case "emailaddress": 
			formattedText = "Email Address";
			break;
		case "city": 
			formattedText = "City";
			break;
		case "expectedprice":
			formattedText = "Expected Price";
			break;
		case "transmissionofcar":
			formattedText = "Transmission of Car";
			break;
		case "carcondition":
			formattedText = "Car Condition";
			break;
		case "claim_number": 
			formattedText = "Claim Number";
			break;
		default: 
			formattedText = unformattedText;
	} 
	return formattedText;
};