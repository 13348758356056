import "../vendor/perfect-scrollbar.css";
import "../assets/css/sidebar.css";

import {
	Avatar,
	Badge,
	Chip,
	Collapse,
	IconButton,
	ListItem,
	ListItemText,
	Box as MuiBox,
	Drawer as MuiDrawer,
	List as MuiList,
	Typography,
} from "@material-ui/core";
import { ChevronLeft, ExpandLess, ExpandMore } from "@material-ui/icons";
import React, { useState } from "react";
import { NavLink as RouterNavLink, withRouter } from "react-router-dom";
import { getCompanyName, getLogoURL, getRole, getUserName } from "../utils";
import {
	roseColor,
	successColor,
} from "../assets/jss/material-dashboard-react.js";

import { BgDangerColor } from "../assets/colors";
import { Layers } from "react-feather";
import PerfectScrollbar from "react-perfect-scrollbar";
import { darken } from "polished";
import { getRoutes } from "../routes/index";
import { makeStyles } from "@material-ui/core/styles";
import { rgba } from "polished";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles({
	bgPrimary: {
		backgroundColor: "white",
		padding: "2px",
		background: "none",
		color: BgDangerColor,
		position: "fixed",
		borderRadius: "0px",
		top: "66px",
		left: "134px",
		zIndex: 1000,
		"&:hover": {
			color: BgDangerColor,
		},
	},
});

const NavLink = React.forwardRef((props, ref) => (
	<RouterNavLink innerRef={ref} {...props} />
));

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
	border-right: 0;

	> div {
		border-right: 0;
	}
`;

const Scrollbar = styled(PerfectScrollbar)`
	background-color: ${(props) => props.theme.sidebar.background};
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
	background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
	padding-top: ${(props) => props.theme.spacing(2.5)}px;
	padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
	font-size: ${(props) => props.theme.typography.h5.fontSize};
	font-weight: ${(props) => props.theme.typography.fontWeightMedium};
	color: ${(props) => props.theme.sidebar.header.color};
	background-color: ${(props) => props.theme.sidebar.header.background};
	font-family: ${(props) => props.theme.typography.fontFamily};
	min-height: 56px;
	padding-left: ${(props) => props.theme.spacing(6)}px;
	padding-right: ${(props) => props.theme.spacing(6)}px;
	cursor: default;

	${(props) => props.theme.breakpoints.up("sm")} {
		min-height: 64px;
	}

	&:hover {
		background-color: ${(props) => successColor[0]};
		color: #fff;
	}
`;

const BrandIcon = styled(Layers)`
	margin-right: ${(props) => props.theme.spacing(2)}px;
	color: ${(props) => props.theme.sidebar.header.brand.color};
`;

const BrandChip = styled(Chip)`
	background-color: ${successColor[0]};
	border-radius: 5px;
	color: ${(props) => props.theme.palette.common.white};
	font-size: 60%;
	height: 20px;
	margin-left: 2px;
	margin-bottom: 1px;
	padding: 4px 0;

	span {
		padding-left: ${(props) => props.theme.spacing(1.5)}px;
		padding-right: ${(props) => props.theme.spacing(1.5)}px;
	}
`;

const Category = styled(ListItem)`
	padding-top: ${(props) => props.theme.spacing(1)}px;
	padding-bottom: ${(props) => props.theme.spacing(1)}px;
	padding-left: ${(props) => props.theme.spacing(1)}px;
	padding-right: ${(props) => props.theme.spacing(1)}px;
	font-weight: ${(props) => props.theme.typography.fontWeightRegular};

	svg {
		color: ${(props) => props.theme.sidebar.color};
		font-size: 20px;
		width: 20px;
		height: 20px;
		opacity: 0.5;
	}

	&:hover {
		background: ${successColor[0]};
		color: #fff;
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) =>
		darken(0.06, props.theme.sidebar.background)};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const CategoryText = styled(ListItemText)`
	margin: 0;
	span {
		color: ${(props) => props.theme.sidebar.color};
		font-size: ${(props) => props.theme.typography.body1.fontSize}px;
		font-weight: ${(props) => props.theme.sidebar.category.fontWeight};
		padding: 0 ${(props) => props.theme.spacing(4)}px;
	}
`;

const CategoryIconLess = styled(ExpandLess)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const AssessmentIconLess = styled(ExpandLess)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
	font-size: 20px;
`;

const AssessmentIconMore = styled(ExpandMore)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
	font-size: 20px;
`;

const Link = styled(ListItem)`
	padding-left: ${(props) => props.theme.spacing(2)}px;
	padding-top: ${(props) => props.theme.spacing(2)}px;
	padding-bottom: ${(props) => props.theme.spacing(2)}px;

	span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
	}

	&:hover span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => successColor[0]};
		color: #fff;

		span {
			color: #fff;
		}
	}
`;

const LinkText = styled(ListItemText)`
	color: ${(props) => props.theme.sidebar.color};
	span {
		font-size: ${(props) => props.theme.typography.body1.fontSize}px;
	}
	margin-top: 0;
	margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 12px;
	top: 8px;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

const CategoryBadge = styled(LinkBadge)`
	top: 12px;
`;

const SidebarSection = styled(Typography)`
	color: ${(props) => props.theme.sidebar.color};
	padding: ${(props) => props.theme.spacing(4)}px
		${(props) => props.theme.spacing(6)}px
		${(props) => props.theme.spacing(1)}px;
	opacity: 0.9;
	display: block;
`;

const SidebarFooter = styled.div`
	background-color: ${(props) =>
		props.theme.sidebar.footer.background} !important;
	padding: ${(props) => props.theme.spacing(2.75)}px
		${(props) => props.theme.spacing(4)}px;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
	color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
	color: ${(props) => props.theme.sidebar.footer.color};
	font-size: 0.725rem;
	display: block;
	padding: 1px;
`;

const StyledBadge = styled(Badge)`
	margin-right: ${(props) => props.theme.spacing(1)}px;

	span {
		background-color: ${(props) =>
		props.theme.sidebar.footer.online.background};
		border: 1.5px solid ${(props) => props.theme.palette.common.white};
		height: 12px;
		width: 12px;
		border-radius: 50%;
	}
`;

function SidebarCategory({
	name,
	icon,
	classes,
	isOpen,
	isCollapsable,
	badge,
	...rest
}) {
	return (
		<Category {...rest}>
			{icon}
			<CategoryText>{name}</CategoryText>
			{isCollapsable ? (
				isOpen ? (
					<CategoryIconMore />
				) : (
						<CategoryIconLess />
					)
			) : null}
			{badge ? <CategoryBadge label={badge} /> : ""}
		</Category>
	);
}

function SidebarLink({ name, to, badge, disabled }) {
	return (
		<Link
			button
			dense
			component={NavLink}
			exact
			to={to}
			disabled={disabled}
			activeClassName='active'
		>
			<LinkText>{name}</LinkText>
			{badge ? <LinkBadge label={badge} /> : ""}
		</Link>
	);
}

function AccordionLink({ name, accordianChildren, icon, expanded = true }) {
	const [accordianToogleFlag, setAccordianToogleFlag] = useState(false);
	function handleChange(event, isExpand) {
		setAccordianToogleFlag(isExpand)
	}
	return (
		<Accordion
			defaultExpanded={expanded}
			onChange={handleChange}
		>
			<AccordionSummary
				// expandIcon={icon}
				aria-controls="panel1bh-content"
				id="panel1bh-header"
			>
				<Typography>{name}</Typography>
				{
					accordianToogleFlag ? (
						<AssessmentIconLess />
					) : (
							<AssessmentIconMore />
						)
				}

			</AccordionSummary>
			<AccordionDetails >
				{
					accordianChildren.map((item, index) =>
						item.visible ? (
							<SidebarLink
								key={index}
								name={item.name}
								to={item.path}
								icon={item.icon}
								badge={item.badge}
								disabled={item.disable || false}
							/>
						) : null

					)
				}
			</AccordionDetails>
		</Accordion>
	)
}

function Sidebar({ classes, staticContext, open, toggleDrawer, ...rest }) {
	const location = useLocation();
	const tokens = location.state?.signIn;
	const routes = getRoutes(getRole(location.state?.signIn?.access));
	const logoURL = getLogoURL(location.state?.signIn?.logo_url);
	const role = getRole(location.state?.signIn?.access);
	const sideClasses = useStyles();
	const pathName = location.pathname;
	const initOpenRoutes = () => {
		/* Open collapse element that matches current url */
		// const pathName = location.pathname;
		let _routes = {};
		routes.forEach((route, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = false;
			const isHome = route.containsHome && pathName === "/" ? true : false;

			_routes = Object.assign({}, _routes, {
				[index]: isActive || isOpen || isHome,
			});
		});

		return _routes;
	};
	const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());
	const toggle = (index) => {
		// Collapse all elements
		Object.keys(openRoutes).forEach(
			(item) =>
				openRoutes[index] ||
				setOpenRoutes((openRoutes) =>
					Object.assign({}, openRoutes, { [item]: false }),
				),
		);

		// Toggle selected element
		setOpenRoutes((openRoutes) =>
			Object.assign({}, openRoutes, { [index]: !openRoutes[index] }),
		);
	};

	// let tx = {0: false, 1: false, 2: true}
	// setOpenRoutes()
	return (
		<Drawer variant={open ? "permanent" : "temporary"} {...rest} open={open}>
			<Brand>
				<Box ml={1}>
					<img src='/static/img/logo/carScan_logo.webp' width='116px' />
				</Box>
			</Brand>
			<Scrollbar>
				{role !== "admin" && (
					<Brand>
						<Box ml={1}>
							<img src={logoURL} width='116px' />
						</Box>
					</Brand>
				)}
				<IconButton
					onClick={toggleDrawer}
					color='primary'
					className={sideClasses.bgPrimary}
				>
					<ChevronLeft />
				</IconButton>
				<List disablePadding>
					<Items>
						{routes.map((category, index) => (
							<React.Fragment key={index}>
								{category.children ? (
									<React.Fragment key={index}>
										<SidebarCategory
											isOpen={false}
											isCollapsable={true}
											name={category.id}
											icon={category.icon}
											button={true}
											onClick={() => toggle(index)}
										/>

										<Collapse
											in={true}
											timeout='auto'
											unmountOnExit
										>
											{category.children.map(
												(route, index) =>
													route.isAccordian ? (
														// {route.expanded = true}
														<AccordionLink key={index}
															{...route}
															expanded={pathName.includes('pre-inspection') ||
																pathName.includes('claim-inspection') ||
																pathName.includes('claim-inspection') ||
																pathName.includes('quotes-to-repair') ?
																true : false 
															}
														/>
													) : (
															route.name && (
																<SidebarLink
																	key={index}
																	name={route.name}
																	to={route.path}
																	icon={route.icon}
																	badge={route.badge}
																/>
															))
											)}

										</Collapse>
									</React.Fragment>
								) : (
										<SidebarCategory
											isCollapsable={false}
											name={category.id}
											to={category.path}
											activeClassName='active'
											component={NavLink}
											icon={category.icon}
											exact
											badge={category.badge}
										/>
									)}
							</React.Fragment>
						))}
					</Items>
				</List>
			</Scrollbar>
			{/* <SidebarFooter>
				<Grid container spacing={2}>
					<Grid item>
						<StyledBadge
							overlap='circle'
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
							variant='dot'
						>
							<Avatar
								alt='Lucy Lavender'
								src='/static/img/avatars/avatar-1.jpg'
							/>
						</StyledBadge>
					</Grid>
					<Grid item>
						<SidebarFooterText variant='body2'>Lucy Lavender</SidebarFooterText>
						<SidebarFooterSubText variant='caption'>
							UX Designer
						</SidebarFooterSubText>
					</Grid>
				</Grid>
			</SidebarFooter> */}
		</Drawer>
	);
}

export default withRouter(Sidebar);
