import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    background-image:url(/static/img/bg.webp)
  }

  body {
    background: "000";
    
  }
`;

const Root = styled.div`
	position: absolute;
	max-width: 520px;
	margin: 200px 120px 0px 100px;
	justify-content: center;
	align-items: center;
	display: flex;

`;

function Auth({ children }) {
	return (
		<>
			<img
				src='/static/img/logo/carScan_logo.webp'
				Style='position: absolute;
    left: 10px;
    top: 10px;
    width:200px;
    '
			/>
			<img
				src='/static/img/Audi.webp'
				Style='position: absolute;
    right: 10px;
    top: 10px;
   '
			/>
			<img
				src='/static/img/iphone_with_logo.webp'
				Style='position: absolute;
    right: 90px;
    top: 145px;

    transform: rotate(7.85700720425217deg)
    '
			/>
			<Root>
				<CssBaseline />
				<GlobalStyle />
				{children}
			</Root>
		</>
	);
}

export default Auth;
