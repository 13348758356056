import "./App.css";

import DateFnsUtils from "@date-io/date-fns";
import Helmet from 'react-helmet';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import React from "react";
import Routes from "./routes/Routes";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";
import { connect } from "react-redux";
import maTheme from "./theme";

function App({ theme }) {
  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | CarScan Dashboard"
        defaultTitle="CarScan Dashboard"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={maTheme[theme.currentTheme]}>
            <ThemeProvider theme={maTheme[theme.currentTheme]}>
              <Routes/>
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default connect(store => ({ theme: store.themeReducer }))(App);
