import {
	Breadcrumbs,
	CssBaseline,
	Hidden,
	IconButton,
	Link,
	Paper as MuiPaper,
	Toolbar,
	Typography,
	withWidth,
} from "@material-ui/core";
import React, { useState } from "react";
import {
	NavLink as RouterNavLink,
	useHistory,
	useLocation,
} from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import { BgDangerColor } from "../assets/colors";
import CheckSession from "../components/CheckSession"
import ChevronRight from "@material-ui/icons/ChevronRight";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Settings from "../components/Settings";
import Sidebar from "../components/Sidebar";
import { isWidthUp } from "@material-ui/core/withWidth";
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import startCase from "lodash/startCase";
import { whiteColor } from "../assets/colors";

const drawerWidth = 160;

const useStyles = makeStyles({
	bgPrimary: {
		backgroundColor: "white",
		borderRadius: "0px 50% 50% 0px",
		paddingRight: "16px",
		boxShadow: "rgba(0, 0, 0, 0.75) 9px 7px 16px -12px",
		color: BgDangerColor,
		position: "fixed",
		top: "136px",
		left: "0px",
		"&:hover": {
			color: BgDangerColor,
		},
	},
});

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.body.background};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
	display: flex;
	min-height: 100vh;
`;

const AppContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
	flex: 1;
	background: ${(props) => props.theme.body.background};

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: none;
	}

	.MuiPaper-root .MuiPaper-root {
		box-shadow: none;
	}
`;
const StyledToolbar = styled(Toolbar)`
	background-color: ${whiteColor};
	min-height: 32px;
`;

const getCrumbs = (pathNames, pathState) => {
	let path = "";

	const breads = [];
	{
		pathNames.map((pathname, index) => {
			if (pathname) {
				path += `/${pathname}`;
				if (index < pathNames.length - 1) {
					const NavLink = React.forwardRef((props, ref) => {
						return (
							<RouterNavLink
								innerRef={ref}
								{...props}
								state={pathState[path]}
							/>
						);
					});
					breads.push(
						<Link
							component={NavLink}
							exact
							to={{ pathname: path, state: pathState[path] }}
							key={`path-${index}`}
						>
							{startCase(pathname)}
						</Link>,
					);
				} else {
					breads.push(<Typography key={`path-${index}`}>{startCase(pathname)}</Typography>);
				}
			}
		});
	}
	return breads;
};

const Dashboard = ({ children, routes, width }) => {
	const history = useHistory();
	const [mobileOpen, setMobileOpen] = useState(false);
	const pathNames = history.location.pathname.split("/");
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	// const [pathState, setPathState] = useState(
	// 	JSON.parse(localStorage.getItem("pathState")) || {},
	// );
	const [sessionStatus, setSessionStatus] = useState(true);
	const location = useLocation();
	const locState = location.state;
	let pathState = JSON.parse(localStorage.getItem("pathState")) || {};
	// useEffect(() => {
	pathState = {
		...pathState,
		...{ [history.location.pathname]: locState },
	};
	localStorage.setItem("pathState", JSON.stringify(pathState));
	// setPathState(tempState);
	// }, [locState]);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	const toggleDrawer = () => {
		setOpen(!open);
	};

	const LeftContainer = styled.div`
		${(props) => props.theme.breakpoints.up("md")} {
			width: ${open ? drawerWidth : 0}px;
			flex-shrink: 0;
		}
	`;

	return (
		<Root>
			<CheckSession setSessionStatus={setSessionStatus}/>
			<CssBaseline />
			<GlobalStyle />
			<LeftContainer>
				<Hidden mdUp implementation='js'>
					<Sidebar
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
						variant='temporary'
						open={open}
						onClose={handleDrawerToggle}
						toggleDrawer={toggleDrawer}
					/>
				</Hidden>
				<Hidden smDown implementation='css'>
					<Sidebar
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
						open={open}
						toggleDrawer={toggleDrawer}
					/>
				</Hidden>
				
			</LeftContainer>
			{!open && (
				<IconButton
					onClick={toggleDrawer}
					color='primary'
					className={classes.bgPrimary}
				>
					<ChevronRight />
				</IconButton>
			)}
			<AppContent>
				<Header onDrawerToggle={handleDrawerToggle} />
				<StyledToolbar>
					<Breadcrumbs aria-label='Breadcrumb' mt={2}>
						{getCrumbs(pathNames, pathState)}
					</Breadcrumbs>
				</StyledToolbar>
				<MainContent p={isWidthUp("lg", width) ? 4 : 2}>{children}</MainContent>
				<Footer />
			</AppContent>
			<Settings />
		</Root>
	);
};

export default withWidth()(Dashboard);
