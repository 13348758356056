import {
  Badge,
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { accessRevoke, refreshRevoke } from '../apiClient';
import { getCompanyName, getUserName } from '../utils';
import styled, { withTheme } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

import { LogOut } from 'react-feather';
import { Menu as MenuIcon } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { darken } from 'polished';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    color: #fff;
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    display: block;
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;
const LogoutIcon = styled(LogOut)`
  color: '#FFF';
`;
const NameGrid = styled(Grid)`
  color: #fff;
  text-align: right;
`;

function UserMenu() {
  const location = useLocation();
  const [anchorMenu, setAnchorMenu] = useState(null);
  const history = useHistory();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const logout = () => {
    Promise.all([refreshRevoke(), accessRevoke()])
      .then(function (response) {
        if (response[0].status === 200) {
          localStorage.clear();
          history.push('/');
          // window.location.reload()
        }
      })
      .catch(function (error) {
        console.error(error);
      });
    closeMenu();
  };

  return (
    <React.Fragment>
      <Tooltip title="Logout">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={(e) => logout()}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>
      {/* <Menu
				id='menu-appbar'
				anchorEl={anchorMenu}
				open={Boolean(anchorMenu)}
				onClose={closeMenu}
			>
				<MenuItem onClick={closeMenu}>Profile</MenuItem>
				<MenuItem onClick={(e) => logout()}>Sign out</MenuItem>
			</Menu> */}
    </React.Fragment>
  );
}

const Header = ({ onDrawerToggle }) => {
  const location = useLocation();
  const userName = getUserName(location.state?.signIn?.username);
  const company = getCompanyName(location.state?.signIn?.company_name);

  return (
    <React.Fragment>
      <AppBar position="sticky" elevation={0} color="primary">
        <Toolbar color="primary">
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            {/* <Grid item>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <Input placeholder="Search topics" />
            </Search>
          </Grid> */}
            <NameGrid item xs>
              <Typography variant="h3">
                {userName} ({company})
              </Typography>
            </NameGrid>
            <Grid item>
              {/* <IconButton color="inherit">
              <Indicator badgeContent={3}>
                <MessageSquare />
              </Indicator>
            </IconButton>
            <IconButton color="inherit">
              <Indicator badgeContent={7}>
                <Bell />
              </Indicator>
            </IconButton>
            <LanguageMenu /> */}
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default connect()(withTheme(Header));
