import axios from "axios";

// import {request} from "./utils/request";

export const baseURL = process.env.REACT_APP_BASE_URL;
export const XAPIKEY = process.env.REACT_APP_XAPIKEY;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_KEY = process.env.REACT_APP_AWS_SECRET_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const AWS_IMAGE_BUCKET = process.env.REACT_APP_AWS_IMAGE_BUCKET;

// export const request = axios.request;

export const getToken = (refresh = false) => {
	const retValue = refresh
		? localStorage.getItem("refresh_token")
		: localStorage.getItem("accessToken");
	return retValue;
};

// axios.interceptor.request.use((config) => console.log(config));

export const getHeaders = (refresh = false) => {
	return { "X-Api-Key": XAPIKEY, Authorization: getToken(refresh) };
};

export const base = {
	baseURL,
	headers: getHeaders(),
};

export const getBase = ({ tokens, refresh = false }) => {
	const newBase = base;
	if (tokens) {
		if (refresh) {
			newBase.headers.Authorization = tokens.request_token;
		} else {
			newBase.headers.Authorization = tokens.access_token;
		}
	}
	return newBase;
};

export const getAllUsers = ({ params = {}, tokens }) =>
	axios.request({
		...getBase({ tokens }),
		method: "get",
		url: "/DashBoardUserDto/getallusers",
		params,
	});

export const getImageUploadLink = (params = {}) =>
	axios.request({
		...base,
		method: "get",
		url: "/DashBoardCompanyDto/getimageuploadlink",
		params,
	});

export const protected1 = (params = {}) =>
	axios.request({
		...base,
		method: "get",
		url: "/DashBoardUserDto/protected1",
		params,
	});

export const protectedURL = (params = {}) =>
	axios.request({
		...base,
		method: "get",
		url: "/DashBoardUserDto/protected",
		params,
	});

export const verifyAdminAccount = (params = {}) =>
	axios.request({
		...base,
		method: "get",
		url: "/DashBoardUserDto/verifyadminaccount",
		params,
	});

// All Post requests
export const forgetPassword = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardUserDto/forgetpassword",
		data,
	});
// fetch (`${baseURL}DashBoardUserDto/forgetpassword`,{
// 	method: "POST",
// 	mode:"cors",
// 	body:JSON.stringify(data),
// 	headers: {
// 		"X-Api-Key": XAPIKEY,
// 		"Content-Type": "application/json",
// 		"Accept": "application/json",
// 		"Access-Control-Allow-Origin": "*",
// 	},
// });

export const loginUser = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "DashBoardUserDto/loginuser",
		data,
	});

export const refresh = () => {
	const headers = getHeaders(true);
	const config = {
		baseURL,
		method: "post",
		url: "/DashBoardUserDto/refresh",
		headers,
	};
	return axios.request(config);
};

export const refreshRevoke = () => {
	const headers = getHeaders(true);
	return axios.request({
		baseURL,
		method: "delete",
		url: "/DashBoardUserDto/refreshrevoke",
		headers,
	});
};

// All Get Requests
export const accessRevoke = () =>
	axios.request({
		method: "delete",
		url: "/DashBoardUserDto/accessrevoke",
		...base,
	});

export const registerUser = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardUserDto/registeruser",
		data,
	});

export const sendAccountActivationLink = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardUserDto/sendaccountactivationlink",
		data,
	});
export const updateUserInfo = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardUserDto/updateuserinfo",
		data,
	});
// POST /DashBoardUserDto/deleteUser
export const deleteUser = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardUserDto/deleteUser",
		data,
	});

export const insertCompany = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardCompanyDto/insertcompany",
		data,
	});

export const updateCompany = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardCompanyDto/updatecompany",
		data,
	});

export const deleteCompany = (data = {}) =>
	axios.request({
		...base,
		method: "post",
		url: "/DashBoardCompanyDto/deleteComapny",
		data,
	});
export const getAssessmentsTable = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getassessmentstable",
		data,
	});

export const getPreAssessmentsTable = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getpreInspectiontable",
		data,
	});


export const getQuotesForAssessment = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getquotesforassessment",
		data,
	});

export const getAIassessmentsForAssessment = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getAIassessmentsforassessment",
		data,
	});
// POST /DashboardData/getaiassessment
export const getAiAssessment = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getaiassessment",
		data,
	});
export const getQuotesAssignedForAssessment = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getquotesassignedforassessment",
		data,
	});

export const getQuoteData = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/getquoteData",
		data,
	});
export const getReturnAssessmentReports = ({ tokens, data = {} }) =>
	axios.request({
		...getBase({ tokens, refresh: false }),
		method: "post",
		url: "/DashboardData/returnAssessmentReports",
		data,
	});

export const resetPassword = (data = {}, token) =>
	axios.request({
		baseURL,
		method: "post",
		url: "DashBoardUserDto/resetpassword",
		data,
		headers: {
			Authorization: token,
			"X-Api-Key": XAPIKEY,
		},
	});

export const getAllCompany = (tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "get",
		url: "/DashBoardCompanyDto/getAllCompany",
	});

// POST /DashBoardGraphDataDto/dailyAssessmentVsDailyQuotes
export const getDailyAssessmentVsDailyQuotes = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/dailyAssessmentVsDailyQuotes",
		data,
	});
// POST /DashBoardGraphDataDto/damageSidesParts
export const getDamageSidesParts = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/damageSidesParts",
		data,
	});
// POST /DashBoardGraphDataDto/funnelDropOffChartsData
export const getFunnelDropOffChartsData = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/funnelDropOffChartsData",
		data,
	});
// POST /DashBoardGraphDataDto/gpsdata
export const getGPSData = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/gpsdata",
		data,
	});
// POST /DashBoardGraphDataDto/minQuotevsmaxQuote
export const getminQuotevsmaxQuote = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/minQuotevsmaxQuote",
		data,
	});
// POST /DashBoardGraphDataDto/repairesQuoteAssignedVsCompleted
export const getRepairesQuoteAssignedVsCompleted = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/repairesQuoteAssignedVsCompleted",
		data,
	});
// POST /DashBoardGraphDataDto/returnCardsgraphData
export const getReturnCardsgraphData = (data, tokens) => {
	return axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/returnCardsgraphData",
		data,
	});
};

// POST /DashBoardGraphDataDto/timeBetweenAssessmentAndQuote
export const getTimeBetweenAssessmentAndQuote = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashBoardGraphDataDto/timeBetweenAssessmentAndQuote",
		data,
	}).catch(console.error);

//POST /DashboardData/getassessmentresultcsv
export const getAssessmentresultcsv = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashboardData/getassessmentresultcsv",
		data,
	});

	//POST /DashboardData/getPreinspectresultcsv
export const getPreinspectresultcsv = (data, tokens) =>
axios.request({
	...getBase({ tokens }),
	method: "post",
	url: "/DashboardData/getpreinspectionresultcsv",
	data,
});

//POST /DashboardData/getQuoteToRepairResultCSV
export const getQuoteToRepairResultCSV = (data, tokens) => {
	data.assessmenttype = "quotetorepair";
	return axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashboardData/getpreinspectionresultcsv",
		data,
	})
};

// POST /DashboardData/insertquotefeedback
export const insertQuoteFeedback = (data, tokens) =>
	axios.request({
		...getBase({ tokens }),
		method: "post",
		url: "/DashboardData/insertquotefeedback",
		data,
	});
